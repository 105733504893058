:root {
  --white: #{$white};
  --black: #{$black};

  --gray-lightest: #{$gray-100};
  --gray-lighter: #{$gray-200};
  --gray-light: #{$gray-400};
  --gray: #{$gray-600};
  --gray-dark: #{$gray-800};

  --indigo-lightest: #{$indigo-100};
  --indigo-lighter: #{$indigo-200};
  --indigo-light: #{$indigo-400};
  --indigo: #{$indigo-600};
  --indigo-dark: #{$indigo-800};

  --blue-lightest: #{$blue-100};
  --blue-lighter: #{$blue-200};
  --blue-light: #{$blue-400};
  --blue: #{$blue-600};
  --blue-dark: #{$blue-800};

  --teal-lightest: #{$teal-100};
  --teal-lighter: #{$teal-200};
  --teal-light: #{$teal-400};
  --teal: #{$teal-600};
  --teal-dark: #{$teal-800};

  --green-lightest: #{$green-100};
  --green-lighter: #{$green-200};
  --green-light: #{$green-400};
  --green: #{$green-600};
  --green-dark: #{$green-800};

  --yellow-lightest: #{$yellow-100};
  --yellow-lighter: #{$yellow-200};
  --yellow-light: #{$yellow-400};
  --yellow: #{$yellow-600};
  --yellow-dark: #{$yellow-800};

  --orange-lightest: #{$orange-100};
  --orange-lighter: #{$orange-200};
  --orange-light: #{$orange-400};
  --orange: #{$orange-600};
  --orange-dark: #{$orange-800};

  --red-lightest: #{$red-100};
  --red-lighter: #{$red-200};
  --red-light: #{$red-400};
  --red: #{$red-600};
  --red-dark: #{$red-800};

  --pink-lightest: #{$pink-100};
  --pink-lighter: #{$pink-200};
  --pink-light: #{$pink-400};
  --pink: #{$pink-600};
  --pink-dark: #{$pink-800};

  --purple-lightest: #{$purple-100};
  --purple-lighter: #{$purple-200};
  --purple-light: #{$purple-400};
  --purple: #{$purple-600};
  --purple-dark: #{$purple-800};

  --gold: #{$gold};
  --silver: #{$silver};
  --bronze: #{$bronze};

  --primary: #{$primary};
  --secondary: #{$secondary};
  --success: #{$success};
  --info: #{$info};
  --warning: #{$warning};
  --danger: #{$danger};
  --light: #{$light};
  --dark: #{$dark};

  --body-color: #{$body-color};
  --body-bg: #{$body-bg};

  --border-color: #{$border-color};
  --tint-color: #{$gray-100};
  --highlight-color: #{$indigo-100};

  --white-rgba: 255, 255, 255;
  --black-rgba: 0, 0, 0;
  --gray-light-rgba: 193, 193, 202;
  --gray-lighter-rgba: 221, 224, 226;

  --shadow: 0 2px 4px rgba(5, 0, 56, 0.1);
  --shadow2: 0 4px 8px rgba(5, 0, 56, 0.1);
  --shadow3: 0 8px 16px rgba(5, 0, 56, 0.1);
  --shadow4: 0 16px 32px rgba(5, 0, 56, 0.1);


  /*
  --white: #{$black};
  --black: #{$white};

  --gray-lightest: #{$gray-800};
  --gray-lighter: #{$gray-600};
  --gray-light: #{$gray-400};
  --gray: #{$gray-200};
  --gray-dark: #{$gray-100};

  --indigo-lightest: #{$indigo-800};
  --indigo-lighter: #{$indigo-600};
  --indigo-light: #{$indigo-400};
  --indigo: #{$indigo-200};
  --indigo-dark: #{$indigo-100};

  --blue-lightest: #{$blue-800};
  --blue-lighter: #{$blue-600};
  --blue-light: #{$blue-400};
  --blue: #{$blue-200};
  --blue-dark: #{$blue-100};

  --teal-lightest: #{$teal-800};
  --teal-lighter: #{$teal-600};
  --teal-light: #{$teal-400};
  --teal: #{$teal-200};
  --teal-dark: #{$teal-100};

  --green-lightest: #{$green-800};
  --green-lighter: #{$green-600};
  --green-light: #{$green-400};
  --green: #{$green-200};
  --green-dark: #{$green-100};

  --yellow-lightest: #{$yellow-800};
  --yellow-lighter: #{$yellow-600};
  --yellow-light: #{$yellow-400};
  --yellow: #{$yellow-200};
  --yellow-dark: #{$yellow-100};

  --orange-lightest: #{$orange-800};
  --orange-lighter: #{$orange-600};
  --orange-light: #{$orange-400};
  --orange: #{$orange-200};
  --orange-dark: #{$orange-100};

  --red-lightest: #{$red-800};
  --red-lighter: #{$red-600};
  --red-light: #{$red-400};
  --red: #{$red-200};
  --red-dark: #{$red-100};

  --pink-lightest: #{$pink-800};
  --pink-lighter: #{$pink-600};
  --pink-light: #{$pink-400};
  --pink: #{$pink-200};
  --pink-dark: #{$pink-100};

  --purple-lightest: #{$purple-800};
  --purple-lighter: #{$purple-600};
  --purple-light: #{$purple-400};
  --purple: #{$purple-200};
  --purple-dark: #{$purple-100};

  --gold: #{$gold};
  --silver: #{$silver};
  --bronze: #{$bronze};

  --primary: #{$primary};
  --secondary: #{$secondary};

  --body-color: #{$body-color};
  --body-bg: #{$body-bg};

  --border-color: #{$border-color};
  --tint-color: #{$gray-100};
  --highlight-color: #{$indigo-100};
*/
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}

@import '../../node_modules/bootstrap/scss/functions';
@import '_variables';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '_root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
//@import "../../node_modules/bootstrap/scss/code";
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
//@import "../../node_modules/bootstrap/scss/jumbotron";
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/progress';
//@import "../../node_modules/bootstrap/scss/media";
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
//@import "../../node_modules/bootstrap/scss/toasts";
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/popover';
@import '../../node_modules/bootstrap/scss/carousel';
//@import "../../node_modules/bootstrap/scss/spinners";
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/print';

/* Disable the error overlay */
/* body > iframe {
  display: none;
} */

@font-face {
  font-family: 'Post Grotesk';
  src: url(fonts/PostGrotesk-Book.woff2) format('woff2'),
    url(fonts/PostGrotesk-Book.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Post Grotesk';
  src: url(fonts/PostGrotesk-Medium.woff2) format('woff2'),
    url(fonts/PostGrotesk-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

/* Dev use: Disable the error overlay */
/* body > iframe {
  display: none;
} */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
  height: 100%;
}

@include media-breakpoint-up(md) {
  html {
    font-size: 16px;
  }
}

body {
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  cursor: pointer;
  font-weight: 500;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

b,
strong {
  font-weight: $font-weight-bold;
}

#root {
  min-height: 100vh;
  height: 100%;
}

label {
  font-weight: 500;
  color: $gray-800;
}
input + label {
  font-weight: normal;
}

.text-lg {
  font-size: $font-size-lg;
}
.text-sm {
  font-size: $font-size-sm;
}
.text-xs {
  font-size: $font-size-xs;
}
.text-gray {
  color: $gray-800;
}
.pull-right {
  float: right;
}

.form-control {
  transition: all 0.1s ease-in-out;
  &:hover {
    border: 1px solid $black;
  }
  &.disabled,
  &:disabled {
    border: 1px solid $gray-400;
  }
}

.subtitle {
  font-weight: 400;
  color: $gray-800;
  margin: 0;
}

.invalid-feedback {
  display: block;
}

.modal-xl {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  max-width: 100%;
  .modal-content {
    height: 100%;
  }
  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    height: 4.75rem;
    .container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.table {
  margin: 0;
}
.table th:first-child,
.table td:first-child {
  padding-left: 1rem;
}
.table th:last-child,
.table td:last-child {
  padding-right: 1rem;
}
.table th {
  font-weight: 500;
}
.table td {
  vertical-align: middle;
}

.container-lg {
  max-width: 1028px;
}
.container-md {
  max-width: 848px;
}
.container-sm {
  max-width: 636px;
}
.container-xs {
  max-width: 504px;
}

.text-xl {
  font-size: 1.25rem;
}

.gutters-sm {
  margin-right: -$grid-gutter-width / 4;
  margin-left: -$grid-gutter-width / 4;

  > .col,
  > [class*='col-'] {
    padding-right: $grid-gutter-width / 4;
    padding-left: $grid-gutter-width / 4;
  }
}

.gutters-sm {
  margin-right: -$grid-gutter-width / 4;
  margin-left: -$grid-gutter-width / 4;

  > .col,
  > [class*='col-'] {
    padding-right: $grid-gutter-width / 4;
    padding-left: $grid-gutter-width / 4;
  }
}

.rc-calendar-picker {
  z-index: 1070 !important;
}

/*
.show > .dropdown-menu {
  visibility: visible;
  margin-top: 0.5rem;
}
.dropdown-menu {
  display: block;
  //margin-top: -0.5rem;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
*/

.fadeIn {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeInAndDown {
  animation: fadeInAndDown 0.2s 0ms forwards;
}
.fadeInAndDown2 {
  animation: fadeInAndDown 0.3s 0ms forwards;
}
.fadeInAndDown2 {
  animation: fadeInAndDown 0.4s 0ms forwards;
}

@keyframes fadeInAndDown {
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chatbot-container {
  height: 100%;
  z-index: 990 !important;
}

/* Some grid gutter classes from Bootstrap 5, remove these if we upgrade */
.g-0 {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.g-1 {
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  > .col,
  > [class*='col-'] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

.g-2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > .col,
  > [class*='col-'] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.g-3 {
  margin-right: -1rem;
  margin-left: -1rem;

  > .col,
  > [class*='col-'] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
